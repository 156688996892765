import '../../i18n';
import React, { Fragment, Suspense, lazy } from 'react';
import { get } from 'lodash';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import getTheme from 'theme';
import { AuthState, useContextValues } from 'context/UserContext';
import MavenLayout from 'components/layouts/MavenLayout';
import isPageWithoutLayout from 'helpers/renderLayout';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { THEMES } from 'constants/common';
import Loader from 'components/common/Loader';
import { LOGIN_PAGE, REPORTS_ACCOUNT_PAGE } from 'constants/routes';
import { QueryClient, QueryClientProvider } from 'react-query';
import GeneralRoutes from 'routes/GeneralRoutes';
import ManageRoutes from 'routes/ManageRoutes';
import ReportRoutes from 'routes/ReportRoutes';

function Container() {
  const queryClient = new QueryClient();
  const { user, authState } = useContextValues();
  const theme = get(user, 'userSettings.theme', false);
  const Layout = isPageWithoutLayout(window.location.pathname) ? Fragment : MavenLayout;

  const LoginPage = lazy(() => import('pages/Login'));

  if (authState === AuthState.DEFAULT) {
    return (
      <ThemeProvider theme={getTheme(theme ? THEMES.DARK : THEMES.LIGHT)}>
        <Loader />
      </ThemeProvider>
    );
  }
  return (
    <ThemeProvider theme={getTheme(theme ? THEMES.DARK : THEMES.LIGHT)}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <Router>
          {authState === AuthState.ERROR && (
            <Routes>
              <Route
                path={LOGIN_PAGE}
                element={
                  <Suspense fallback={<Loader />}>
                    <LoginPage />
                  </Suspense>
                }
              />
              <Route path="*" element={<Navigate replace to={LOGIN_PAGE} />} />
            </Routes>
          )}
          {authState === AuthState.SUCCESS && (
            <Layout>
              <Routes>
                <Route path="/" element={<Navigate replace to={REPORTS_ACCOUNT_PAGE} />} />
                <Route path="reports/*" element={<ReportRoutes />} />
                <Route path="manage/*" element={<ManageRoutes />} />
                <Route path="*" element={<GeneralRoutes user={user} />} />
              </Routes>
            </Layout>
          )}
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default Container;
