import { GroupBy } from 'constants/types/Report';
import { get } from 'lodash';

const urlOptions = {
  All: '',
  Daily: '/daily',
  Hourly: '/hourly',
};
const getGroupByOption = function buildGroupByURLOptionHelper(groupBy: GroupBy) {
  return get(urlOptions, `${groupBy}`, '');
};

export default getGroupByOption;
