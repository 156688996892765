import { Box, CircularProgress } from '@mui/material';

interface ILoader {
  height?: string;
}
const Loader = ({ height = '100vh' }: ILoader) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight={height}>
      <CircularProgress />
    </Box>
  );
};

export default Loader;
