import { Stack, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { IListItem } from 'constants/types/ApprovedData';
import moment from 'moment';

const ApprovedDataListItem = ({ data, isRealtime }: IListItem) => {
  const { name, totalCount, successCount, lastCurrentSuccessfulImportDate, status } = data;

  return (
    <Stack direction="row" spacing={2} alignItems="flex-end" justifyContent="space-between">
      <Stack direction="row" alignItems="center">
        {status === 1 ? (
          <CircleIcon fontSize="inherit" color="success" />
        ) : (
          <CircleOutlinedIcon fontSize="inherit" color={status === 2 ? 'primary' : 'error'} />
        )}
        <Typography
          sx={{ fontWeight: 'regular', textAlign: 'left', fontSize: 12, ml: 1 }}
        >{`${name} (${successCount}/${totalCount})`}</Typography>
      </Stack>
      {isRealtime && (
        <Typography
          sx={{ fontWeight: 'light', fontStyle: 'italic', fontSize: 10, textAlign: 'right' }}
        >
          {moment(lastCurrentSuccessfulImportDate).fromNow()}
        </Typography>
      )}
    </Stack>
  );
};

export default ApprovedDataListItem;
