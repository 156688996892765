import { useTranslation } from 'react-i18next';
import { Icon, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { ISidebarItem } from 'constants/types/Sidebar';
import { useContextValues } from 'context/UserContext';
import { get } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

const SidebarItem = ({ title, icon, pagePath }: ISidebarItem) => {
  const { user } = useContextValues();
  const expandedSidebar = get(user, 'userSettings.expandedSidebar', false);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { t } = useTranslation();
  const translationPath = 'sidebar.items';
  const completePath = `${pathname}${search}`;

  return (
    <ListItem disablePadding={true} sx={{ display: 'block' }}>
      <ListItemButton onClick={() => navigate(pagePath)}>
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: expandedSidebar ? 3 : 'auto',
            justifyContent: 'center',
            color: (theme) =>
              completePath === `${pagePath}` ? theme.palette.primary.main : 'default',
          }}
        >
          <Icon>{icon}</Icon>
        </ListItemIcon>
        <ListItemText
          primary={t(`${translationPath}.${title}`)}
          sx={{
            opacity: expandedSidebar ? 1 : 0,
            color: (theme) =>
              completePath === `${pagePath}` ? theme.palette.primary.main : 'default',
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default SidebarItem;
