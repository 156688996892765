export const HOME_PAGE = '/';
export const LOGIN_PAGE = '/login';
export const PROFILE_PAGE = '/user-profile';
export const REPORTS_ACCOUNT_PAGE = '/reports/accounts';
export const REPORTS_INTERNAL_ACCOUNT_PAGE = '/reports/accounts?internalAccount=true';
export const REPORTS_INITIATIVES_PAGE = '/reports/initiatives';
export const REPORTS_CAMPAIGNS_PAGE = '/reports/campaigns';
export const REPORTS_ADGROUPS_PAGE = '/reports/adGroups';
export const REPORTS_DATA_SOURCES_PAGE = '/reports/dataSources';
export const REPORTS_LANDING_PAGES = '/reports/landingPages';
export const REPORTS_CONTENT_ADS_PAGE = '/reports/contentAds';
export const MANAGE_ACCOUNTS = '/reports/accounts';
export const MANAGE_USERS = '/manage/users';
export const CREATE_USER = '/manage/users/create';
export const COS_DASHBOARD_PAGE = '/monitor/cos-data';

export const MONITOR_REDIRECT_URL = '/monitor'; // Path to where Maven legacy redirects the user targeting Monitor view in Maven 2.0

export const USER_DETAILS = 'users/detail/:id';
export const EDIT_USER = 'users/edit/:id';
export const VIEW_USER_DETAILS = (id: string) => `/manage/users/detail/${id}`;
export const EDIT_USER_DETAILS = (id: string) => `/manage/users/edit/${id}`;

export const MAVEN_LEGACY_HOMEPAGE = process.env.REACT_APP_MAVEN_LEGACY_URL;

export const PAGE_ROUTES_WITHOUT_LAYOUT = [LOGIN_PAGE, HOME_PAGE];
export const REPORT_PAGES = [
  REPORTS_ACCOUNT_PAGE,
  REPORTS_INITIATIVES_PAGE,
  REPORTS_CAMPAIGNS_PAGE,
  REPORTS_ADGROUPS_PAGE,
  REPORTS_DATA_SOURCES_PAGE,
  REPORTS_LANDING_PAGES,
  REPORTS_CONTENT_ADS_PAGE,
];

export const MANAGE_PAGES = [MANAGE_USERS, CREATE_USER];
