import { AxiosRequestConfig } from 'axios';
import buildQueryParams from 'helpers/buildQueryParams';
import getGroupByOption from 'components/Report/helpers/getGroupByOption';
import { Filter, IReportsFilter } from './types/Filter';
import {
  ICreateUserPayload,
  IEditUserPayload,
  ILoginRequestPayload,
  IUpdatePasswordPayload,
  IUpdateProfileDetailsPayload,
  IReportingSettingsPayload,
  IImpersonateUserPayload,
} from './types/Payload';
import { GroupBy, IReportQueryOptions } from './types/Report';
import { IUserSettings } from './types/User';

const version = 'v2';

export const GET_USER: AxiosRequestConfig = {
  method: 'GET',
  url: `${version}/authorization/user`,
};

export const LOGOUT: AxiosRequestConfig = {
  method: 'GET',
  url: 'authorization/logout',
};

export const GET_REPORTING_SETTINGS: AxiosRequestConfig = {
  method: 'GET',
  url: `${version}/reporting/settings`,
};

export const GET_ROLES: AxiosRequestConfig = {
  method: 'GET',
  url: 'users/roles',
};

export const GET_STATUS: AxiosRequestConfig = {
  method: 'GET',
  url: 'accounts/status',
};

export const GET_FILTER_DATA = function buildGetFilterDataRequest(
  filterName: string,
  params: IReportsFilter,
): AxiosRequestConfig {
  const query = buildQueryParams({}, params);
  return {
    method: 'GET',
    url: `${version}/report/filter/${filterName}?sortBy=name&asc=true&${query}`,
  };
};

export const GET_ACCOUNT_REPORTS_DATA = function buildGetAccountReportsDataRequest(
  reportQueryOptions: IReportQueryOptions,
  filter: Filter | null,
  groupBy: GroupBy = GroupBy.All,
): AxiosRequestConfig {
  const query = buildQueryParams(reportQueryOptions, filter);
  const groupByPath = getGroupByOption(groupBy);
  const report = groupBy !== GroupBy.Daily ? '/accounts' : '';
  return {
    method: 'GET',
    url: `${version}/report${groupByPath}${report}?${query}`,
  };
};

export const GET_INITIATIVES_REPORTS_DATA = function buildGetInitiativesReportsDataRequest(
  reportQueryOptions: IReportQueryOptions,
  filter: Filter | null,
  groupBy: GroupBy = GroupBy.All,
): AxiosRequestConfig {
  const query = buildQueryParams(reportQueryOptions, filter);
  const groupByPath = getGroupByOption(groupBy);
  const report = groupBy !== GroupBy.Daily ? '/initiatives' : '';
  return {
    method: 'GET',
    url: `${version}/report${groupByPath}${report}?${query}`,
  };
};

export const GET_CAMPAIGN_REPORTS_DATA = function buildGetCamapignReportsDataRequest(
  reportQueryOptions: IReportQueryOptions,
  filter: Filter | null,
  groupBy: GroupBy = GroupBy.All,
): AxiosRequestConfig {
  const query = buildQueryParams(reportQueryOptions, filter);
  const groupByPath = getGroupByOption(groupBy);
  const report = groupBy !== GroupBy.Daily ? '/campaigns' : '';
  return {
    method: 'GET',
    url: `${version}/report${groupByPath}${report}?${query}`,
  };
};

export const GET_ADGROUP_REPORTS_DATA = function buildGetAdGroupReportsDataRequest(
  reportQueryOptions: IReportQueryOptions,
  filter: Filter | null,
  groupBy: GroupBy = GroupBy.All,
): AxiosRequestConfig {
  const query = buildQueryParams(reportQueryOptions, filter);
  const groupByPath = getGroupByOption(groupBy);
  const report = groupBy !== GroupBy.Daily ? '/adgroups' : '';
  return {
    method: 'GET',
    url: `${version}/report${groupByPath}${report}?${query}`,
  };
};

export const GET_DATA_SOURCES_REPORTS_DATA = function buildGetDataSourcesReportsDataRequest(
  reportQueryOptions: IReportQueryOptions,
  filter: Filter | null,
): AxiosRequestConfig {
  const query = buildQueryParams(reportQueryOptions, filter);
  return {
    method: 'GET',
    url: `${version}/report/trafficsources?${query}`,
  };
};

export const GET_LANDING_PAGES_REPORTS_DATA = function buildGetLandingPagesReportsDataRequest(
  reportQueryOptions: IReportQueryOptions,
  filter: Filter | null,
  groupBy: GroupBy = GroupBy.All,
): AxiosRequestConfig {
  const query = buildQueryParams(reportQueryOptions, filter);
  const groupByPath = getGroupByOption(groupBy);
  const report = groupBy !== GroupBy.Daily ? '/landingpages' : '';
  return {
    method: 'GET',
    url: `${version}/report${groupByPath}${report}/?${query}`,
  };
};

export const GET_CONTENT_ADS_REPORTS_DATA = function buildGetContentAdsReportsDataRequest(
  reportQueryOptions: IReportQueryOptions,
  filter: Filter | null,
  groupBy: GroupBy = GroupBy.All,
): AxiosRequestConfig {
  const query = buildQueryParams(reportQueryOptions, filter);
  const groupByPath = getGroupByOption(groupBy);
  const report = groupBy !== GroupBy.Daily ? '/contentads' : '';
  return {
    method: 'GET',
    url: `${version}/report${groupByPath}${report}/?${query}`,
  };
};

export const LOGIN = function buildLoginRequest(data: ILoginRequestPayload): AxiosRequestConfig {
  return {
    method: 'POST',
    url: `${version}/authorization/login`,
    data,
  };
};

export const UPDATE_USER_SETTINGS = function buildUpdateUserSettingsRequest(
  userSettings: IUserSettings,
): AxiosRequestConfig {
  return {
    method: 'PUT',
    url: 'users/settings',
    data: userSettings,
  };
};

export const UPDATE_USER_PASSWORD = function buildUpdateUserPasswordRequest(
  payload: IUpdatePasswordPayload,
): AxiosRequestConfig {
  return {
    method: 'PUT',
    url: 'profile/password',
    data: payload,
  };
};

export const UPDATE_PROFILE_DETAILS = function buildUpdateUserDetailsRequest(
  payload: IUpdateProfileDetailsPayload,
): AxiosRequestConfig {
  return {
    method: 'PUT',
    url: 'profile',
    data: payload,
  };
};

export const GET_USER_PROFILE = function buildGetUserProfileRequest(
  id: string,
): AxiosRequestConfig {
  return {
    method: 'GET',
    url: `${version}/users/${id}`,
  };
};

export const CREATE_USER = function buildCreateUserRequest(
  data: ICreateUserPayload,
): AxiosRequestConfig {
  return {
    method: 'POST',
    url: '/Users',
    data,
  };
};

export const GET_ACCOUNTS = function buildGetAccounsDataRequest(
  reportQueryOptions: IReportQueryOptions,
): AxiosRequestConfig {
  const reportQuery = buildQueryParams(reportQueryOptions);
  return {
    method: 'GET',
    url: `/accounts?${reportQuery}`,
  };
};

export const UPDATE_USER_DETAILS = function buildUpdateUserDetailsRequest(
  id: string,
  userDetails: IEditUserPayload,
): AxiosRequestConfig {
  return {
    method: 'PUT',
    url: `/users/${id}`,
    data: userDetails,
  };
};

export const SAVE_REPORTING_SETTINGS = function buildSaveReportingSettingsRequest(
  payload: IReportingSettingsPayload,
): AxiosRequestConfig {
  return {
    method: 'PUT',
    url: `/reporting/settings`,
    data: payload,
  };
};

export const GET_USERS_LIST = function buildGetUsersListRequest(
  reportQueryOptions: IReportQueryOptions,
  filter: Filter | null,
): AxiosRequestConfig {
  const query = buildQueryParams(reportQueryOptions, filter);
  return {
    method: 'GET',
    url: `${version}/users?${query}`,
  };
};

export const EXPORT_REPORTS_DATA = function buildExportReportDataRequest(
  reportQueryOptions: IReportQueryOptions,
  exportMode: string,
  genBy: string,
): AxiosRequestConfig {
  const reportQuery = buildQueryParams(reportQueryOptions);
  return {
    method: 'GET',
    url: `initiativedata/report/account/export?${reportQuery}&exportMode=${exportMode}&genBy=${genBy}&level=Accounts`, // TODO: Create query option interfaces with inheritance
    responseType: 'blob',
  };
};

export const GET_REALTIME_APPROVAL_STATUS: AxiosRequestConfig = {
  method: 'GET',
  url: 'data-approval/approval-status-realtime',
};

export const GET_RANGE_APPROVAL_STATUS = function buildRangeApprovalStatusRequest(
  reportQueryOptions: IReportQueryOptions,
): AxiosRequestConfig {
  const reportQuery = buildQueryParams(reportQueryOptions);
  return {
    method: 'GET',
    url: `data-approval/approval-status-range?${reportQuery}`,
  };
};

export const IMPERSONATE = function buildImpersonationRequest(
  data: IImpersonateUserPayload,
): AxiosRequestConfig {
  return {
    method: 'POST',
    url: '/authorization/impersonate',
    data,
  };
};

export const GENERATE_LANDING_PAGE_URL = function buildGenerateLandingPageUrlRequest(
  sourceId: number,
  initiativeId?: string | null,
  landingPageId?: number,
): AxiosRequestConfig {
  const query = buildQueryParams(
    {},
    { landingPageId, sourceId, initiativeId: initiativeId || undefined },
  );
  return {
    method: 'GET',
    url: `/landingpages/generateurl?${query}`,
  };
};
