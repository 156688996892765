import { useState, createContext, ReactNode, useContext, useEffect } from 'react';
import {
  GET_ACCOUNTS,
  GET_FILTER_DATA,
  GET_REPORTING_SETTINGS,
  GET_ROLES,
  GET_STATUS,
  GET_USER,
  IMPERSONATE,
  LOGIN,
  LOGOUT,
  SAVE_REPORTING_SETTINGS,
  UPDATE_USER_SETTINGS,
} from 'constants/endpoints';
import {
  IImpersonateUserPayload,
  ILoginRequestPayload,
  IReportingSettingsPayload,
} from 'constants/types/Payload';
import { IUser, IUserSettings } from 'constants/types/User';
import fetcher from 'helpers/fetcher';
import {
  ATN_KEY,
  MANAGEUSERS_FILTER_KEY,
  ORIGINAL_ATN_KEY,
  REPORTS_FILTER_KEY,
  USE_ACCOUNT_DATA_KEY,
} from 'constants/common';
import {
  IInitiative,
  IReport,
  IReportQueryOptions,
  IUserReportColumns,
  ReportType,
} from 'constants/types/Report';
import { IRole } from 'constants/types/Role';
import { IStatus } from 'constants/types/Status';
import { IAccount } from 'constants/types/Account';
import { IFilterData, IManageUserFilter, IReportsFilter } from 'constants/types/Filter';
import { INITIAL_QUERY_OPTIONS } from 'constants/reports';

export const userContext = createContext({
  user: ({} as IUser) || null,
  authState: 'DEFAULT',
  logout: () => {},
  login: (data: ILoginRequestPayload): any => data,
  impersonateUser: (data: IImpersonateUserPayload): any => data,
  toggleSettings: (value: string, shouldSaveSettings: boolean): any => value && shouldSaveSettings,
  reports: [] as IReport[],
  initiatives: [] as IInitiative[],
  userReportColumns: [] as IUserReportColumns[],
  roles: [] as IRole[],
  status: [] as IStatus[],
  accounts: [] as IAccount[],
  dataSources: [] as IFilterData[],
  reportsFilter: {} || null,
  manageUsersFilter: {} || null,
  useAccountData: false,
  reportQueryOptions: {} as IReportQueryOptions,
  updateReportsFilter: (updatedFilter: IReportsFilter): any => updatedFilter,
  updateManageUsersFilter: (updatedFilter: IManageUserFilter): any => updatedFilter,
  updateReportQueryOptions: (updatedQuery: IReportQueryOptions): any => updatedQuery,
  saveReportingSettings: (payload: IReportingSettingsPayload): any => payload,
  toggleUseAccountData: (key: string, useAccountData: boolean): any => key && useAccountData,
});

interface IContextProvider {
  children: ReactNode | ReactNode[];
}

export enum AuthState {
  DEFAULT = 'DEFAULT',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export const useContextValues = () => {
  return useContext(userContext);
};

export const UserProvider = ({ children }: IContextProvider) => {
  const [user, setUser] = useState<IUser>({
    id: '',
    accounts: [],
    email: '',
    name: '',
    role: 'Admin',
    userSettings: {
      expandedSidebar: true,
      theme: false,
    },
  });

  const getInitialUseAccountData = () => {
    let initialValue = false;
    if (typeof window !== 'undefined')
      initialValue = window.localStorage.getItem(USE_ACCOUNT_DATA_KEY) === 'true';
    return initialValue;
  };

  const [authState, setAuthState] = useState<AuthState>(AuthState.DEFAULT);
  const [reports, setReports] = useState<IReport[]>([]);
  const [initiatives, setInitiatives] = useState<IInitiative[]>([]);
  const [userReportColumns, setUserReportColumns] = useState<IUserReportColumns[]>([]);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [status, setStatus] = useState<IStatus[]>([]);
  const [accounts, setAccounts] = useState<IAccount[]>([]);
  const [dataSources, setDataSources] = useState<IFilterData[]>([]);
  const [reportsFilter, setReportsFilter] = useState<IReportsFilter | null>(null);
  const [manageUsersFilter, setManageUsersFilter] = useState<IManageUserFilter | null>(null);
  const [useAccountData, setUseAccountData] = useState<boolean>(getInitialUseAccountData);
  const [reportQueryOptions, setReportQueryOptions] =
    useState<IReportQueryOptions>(INITIAL_QUERY_OPTIONS);

  const login = async (payload: ILoginRequestPayload): Promise<boolean> => {
    const { error } = await fetcher(LOGIN(payload));

    if (error) return false;

    setAuthState(AuthState.SUCCESS);
    return true;
  };

  const impersonateUser = async (payload: IImpersonateUserPayload): Promise<boolean> => {
    const { data } = await fetcher(IMPERSONATE(payload));

    if (data?.accessToken) {
      const { accessToken } = data;
      const originalKey = window.localStorage.getItem(ATN_KEY);
      if (originalKey) window.localStorage.setItem(ORIGINAL_ATN_KEY, originalKey);
      window.localStorage.setItem(ATN_KEY, accessToken);
      //setAuthKey(accessToken);
      return true;
    }
    return false;
  };

  const logout = async () => {
    await fetcher(LOGOUT);
    if (typeof window !== 'undefined') {
      setAuthState(AuthState.ERROR);
    }
  };

  const toggleSettings = async (value: string, shouldSaveSettings: boolean): Promise<void> => {
    const { userSettings } = user;
    const requestConfig = UPDATE_USER_SETTINGS({
      ...userSettings,
      [value]: !userSettings[value as keyof IUserSettings],
    });

    setUser({
      ...user,
      userSettings: {
        ...userSettings,
        [value]: !userSettings[value as keyof IUserSettings],
      },
    });

    if (shouldSaveSettings) await fetcher(requestConfig);
  };

  const toggleUseAccountData = (key: string, value: boolean) => {
    setUseAccountData(value);
    window.localStorage.setItem(key, String(value));
  };

  const fetchUser = async () => {
    const { data, error } = await fetcher(GET_USER);

    if (error) {
      setAuthState(AuthState.ERROR);
      return null;
    }

    setAuthState(AuthState.SUCCESS);
    return data;
  };

  const fetchReportingSettings = async () => {
    const { data, error } = await fetcher(GET_REPORTING_SETTINGS);

    if (error) return {};
    return data;
  };

  const fetchUserRoles = async () => {
    const { data, error } = await fetcher(GET_ROLES);

    if (error) return {};
    return data;
  };

  const fetchAccounts = async () => {
    const requestConfig = GET_ACCOUNTS({ orderBy: 'name', asc: false, page: 0, pageSize: 0 });
    const { data, error } = await fetcher(requestConfig);

    if (error) return {};

    return data?.data;
  };

  const fetchDataSources = async () => {
    const request = GET_FILTER_DATA(ReportType.DATA_SOURCE_REPORT, {});
    const { data, error } = await fetcher(request);

    return !error ? data : {};
  };

  const fetchAccountStatus = async () => {
    const { data, error } = await fetcher(GET_STATUS);

    if (error) return {};
    return data;
  };

  const updateReportsFilter = (updatedFilter: IReportsFilter) => {
    setReportsFilter(updatedFilter);
    localStorage.setItem(REPORTS_FILTER_KEY, JSON.stringify(updatedFilter));
  };

  const updateManageUsersFilter = (updatedFilter: IManageUserFilter) => {
    setManageUsersFilter(updatedFilter);
    localStorage.setItem(MANAGEUSERS_FILTER_KEY, JSON.stringify(updatedFilter));
  };

  const updateReportQueryOptions = (updatedQuery: IReportQueryOptions) => {
    setReportQueryOptions(updatedQuery);
  };

  const saveReportingSettings = async (payload: IReportingSettingsPayload) => {
    await fetcher(SAVE_REPORTING_SETTINGS(payload));
  };

  useEffect(() => {
    const localReportsFilter = localStorage.getItem(REPORTS_FILTER_KEY);

    if (localReportsFilter) setReportsFilter(JSON.parse(localReportsFilter));
  }, []);

  useEffect(() => {
    // TODO: refactor the Impersonate User logic
    //const originalKey = window.localStorage.getItem(ORIGINAL_ATN_KEY);
    //   if (user.id) {
    //     const fetchDataForImpersonation = async () => {
    //       const { data } = await fetchReportingSettings(currentKey);
    //       if (data) {
    //         const {
    //           reports: userReports,
    //           initiatives: userInitiatives,
    //           userReportColumns: userReportColumnSettings,
    //         } = data;
    //         setReports(userReports);
    //         setInitiatives(userInitiatives);
    //         setUserReportColumns(userReportColumnSettings);
    //       }
    //     };

    //     fetchDataForImpersonation();
    //   } else {

    const fetchDataForContext = async () => {
      const userData = await fetchUser();
      if (userData) {
        setUser(userData);
        const [reportingSettingsData, rolesData, accountsData, statusData, dataSources] =
          await Promise.all([
            fetchReportingSettings(),
            fetchUserRoles(),
            fetchAccounts(),
            fetchAccountStatus(),
            fetchDataSources(),
          ]);
        const {
          reports: userReports,
          initiatives: userInitiatives,
          userReportColumns: userReportColumnSettings,
        } = reportingSettingsData;
        setReports(userReports);
        setInitiatives(userInitiatives);
        setUserReportColumns(userReportColumnSettings);
        setRoles(rolesData);
        setAccounts(accountsData);
        setStatus(statusData);
        setDataSources(dataSources);
      }
    };

    if (authState !== 'ERROR') fetchDataForContext();
  }, [authState]);

  return (
    <userContext.Provider
      value={{
        user,
        logout,
        login,
        impersonateUser,
        toggleSettings,
        authState,
        reports,
        initiatives,
        userReportColumns,
        roles,
        status,
        accounts,
        dataSources,
        reportsFilter,
        manageUsersFilter,
        useAccountData,
        reportQueryOptions,
        updateReportsFilter,
        updateManageUsersFilter,
        updateReportQueryOptions,
        saveReportingSettings,
        toggleUseAccountData,
      }}
    >
      {children}
    </userContext.Provider>
  );
};
