import { useState, createContext, ReactNode, useContext, useCallback } from 'react';
import { GET_FILTER_DATA } from 'constants/endpoints';
import fetcher from 'helpers/fetcher';
import { IFilterData, IReportsFilter } from 'constants/types/Filter';
import { ReportType } from 'constants/types/Report';

export const FilterContext = createContext({
  dataSources: [] as IFilterData[],
  accounts: [] as IFilterData[],
  initiatives: [] as IFilterData[],
  landingPages: [] as IFilterData[],
  campaigns: [] as IFilterData[],
  adGroups: [] as IFilterData[],
  contentAds: [] as IFilterData[],
  previousAccountType: '' as string | null,
  // eslint-disable-next-line
  fetchDataSources: (params: IReportsFilter): any => params,
  // eslint-disable-next-line
  fetchAccounts: (params: IReportsFilter): any => params,
  fetchInitiatives: (params: IReportsFilter): any => params,
  fetchLandingPages: (params: IReportsFilter): any => params,
  fetchCampaigns: (params: IReportsFilter): any => params,
  fetchAdGroups: (params: IReportsFilter): any => params,
  fetchContentAds: (params: IReportsFilter): any => params,
  setPreviousAccountType: (type: string | null): any => type,
});

interface IContextProvider {
  children: ReactNode | ReactNode[];
}

export const useFilterValues = () => {
  return useContext(FilterContext);
};

export const FilterProvider = ({ children }: IContextProvider) => {
  const [dataSources, setDataSources] = useState<IFilterData[]>([]);
  const [accounts, setAccounts] = useState<IFilterData[]>([]);
  const [initiatives, setInitiatives] = useState<IFilterData[]>([]);
  const [landingPages, setLandingPages] = useState<IFilterData[]>([]);
  const [campaigns, setCampaigns] = useState<IFilterData[]>([]);
  const [adGroups, setAdGroups] = useState<IFilterData[]>([]);
  const [contentAds, setContentAds] = useState<IFilterData[]>([]);
  const [previousAccountType, setPreviousAccountType] = useState<string | null>('');

  const fetchDataSources = useCallback(async (params: IReportsFilter) => {
    const request = GET_FILTER_DATA(ReportType.DATA_SOURCE_REPORT, params);
    const { data } = await fetcher(request);
    if (data) setDataSources(data);
  }, []);

  const fetchAccounts = useCallback(async (params: IReportsFilter) => {
    const request = GET_FILTER_DATA(ReportType.ACCOUNT_REPORT, params);
    const { data } = await fetcher(request);
    if (data && data.length) setAccounts(data);
  }, []);

  const fetchInitiatives = useCallback(async (params: IReportsFilter) => {
    const request = GET_FILTER_DATA(ReportType.INITIATIVE_REPORT, params);
    const { data } = await fetcher(request);
    if (data) setInitiatives(data);
  }, []);

  const fetchLandingPages = useCallback(async (params: IReportsFilter) => {
    const request = GET_FILTER_DATA(ReportType.LANDING_PAGE_REPORT, params);
    const { data } = await fetcher(request);
    if (data && data.length) setLandingPages(data);
  }, []);

  const fetchCampaigns = useCallback(async (params: IReportsFilter) => {
    const request = GET_FILTER_DATA(ReportType.CAMPAIGN_REPORT, params);
    const { data } = await fetcher(request);
    if (data && data.length) setCampaigns(data);
  }, []);

  const fetchAdGroups = useCallback(async (params: IReportsFilter) => {
    const request = GET_FILTER_DATA(ReportType.AD_GROUPS_REPORT, params);
    const { data } = await fetcher(request);
    if (data && data.length) {
      setAdGroups(data);
    }
  }, []);

  const fetchContentAds = useCallback(async (params: IReportsFilter) => {
    const request = GET_FILTER_DATA(ReportType.CONTENT_ADS_REPORT, params);
    const { data } = await fetcher(request);
    if (data && data.length) {
      setContentAds(data);
    }
  }, []);

  return (
    <FilterContext.Provider
      value={{
        dataSources,
        accounts,
        initiatives,
        landingPages,
        campaigns,
        adGroups,
        contentAds,
        previousAccountType,
        fetchDataSources,
        fetchAccounts,
        fetchInitiatives,
        fetchLandingPages,
        fetchCampaigns,
        fetchAdGroups,
        fetchContentAds,
        setPreviousAccountType,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
