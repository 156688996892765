export const THEME = 'theme';

export enum THEMES {
  LIGHT = 'light',
  DARK = 'dark',
}
export const EXTENDED_SIDEBAR = 'extendedSidebar';
export const APPBAR_HEIGHT = 18;
export const FILTER_DROPDOWN_WIDTH = 250;
export const FILTER_DROPDOWN_HEIGHT = 270;

export const ATN_KEY = 'atn-key';
export const ORIGINAL_ATN_KEY = 'original-atn-key';
export const REPORTS_FILTER_KEY = 'reports-filter';
export const REPORT_DATA_KEY = 'report-data';
export const MANAGEUSERS_FILTER_KEY = 'manageusers-filter';
export const USE_ACCOUNT_DATA_KEY = 'use-account-data';
export const NAME_OF_CURRENT_ENTITY_KEY = 'name-of-entity';

export const FAILURE_VALUE = 'N/A';

export const SORT_BY_QUERY_OPTION = 'sortBy';
export const SORT_BY_REDIRECTS = 'redirects';
export const SORT_BY_ACCOUNT = 'account';
export const SORT_BY_DATE = 'date';
export const SORT_BY_HOUR = 'hour';

export const SINGLE_DIGIT = 1;
export const TWO_DIGITS = 2;
export const THREE_DIGITS = 3;
