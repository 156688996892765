import { PaletteOptions } from '@mui/material/styles/createPalette';
import { PaletteMode } from '@mui/material';

const paletteOptions: PaletteOptions = {
  primary: { main: '#FF8000', contrastText: '#fff' },
};

const getThemePalette = (mode: PaletteMode): PaletteOptions => ({
  mode,
  ...(mode === 'light'
    ? {
        // palette values for light mode
        ...paletteOptions,
        secondary: { main: '#363F63' },
      }
    : {
        // palette values for dark mode
        ...paletteOptions,
        secondary: { main: '#fff' },
      }),
});

export default getThemePalette;
