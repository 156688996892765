export const HOME_PAGE = '/';
export const LOGIN_PAGE = '/login';
export const PROFILE_PAGE = '/user-profile';
export const REPORTS_ACCOUNT_PAGE = '/reports/accounts';
export const REPORTS_INTERNAL_ACCOUNT_PAGE = '/reports/accounts?internalAccount=true';
export const REPORTS_INITIATIVES_PAGE = '/reports/initiatives';
export const REPORTS_CAMPAIGNS_PAGE = '/reports/campaigns';
export const REPORTS_ADGROUPS_PAGE = '/reports/adGroups';
export const REPORTS_DAILY_INITIATIVES_PAGE = '/reports/initiatives/daily';
export const REPORTS_DAILY_CAMPAIGNS_PAGE = '/reports/campaigns/daily';
export const REPORTS_DAILY_LANDING_PAGES_PAGE = '/reports/landingPages/daily';
export const REPORTS_DAILY_ADGROUPS_PAGE = '/reports/adGroups/daily';
export const REPORTS_DAILY_CONTENT_ADS_PAGE = '/reports/contentAds/daily';
export const REPORTS_DATA_SOURCES_PAGE = '/reports/dataSources';
export const REPORTS_LANDING_PAGES = '/reports/landingPages';
export const REPORTS_CONTENT_ADS_PAGE = '/reports/contentAds';
export const MANAGE_ACCOUNTS = '/manage/accounts';
export const MANAGE_USERS = '/manage/users';
export const CREATE_USER = '/manage/users/create';
export const COS_DASHBOARD_PAGE = '/cos-data';

export const VIEW_USER_DETAILS = (id: string) => `/manage/users/detail/${id}`;
export const EDIT_USER_DETAILS = (id: string) => `/manage/users/edit/${id}`;

export const PAGE_ROUTES_WITHOUT_LAYOUT = [LOGIN_PAGE];
export const REPORT_PAGES = [
  REPORTS_ACCOUNT_PAGE,
  REPORTS_INTERNAL_ACCOUNT_PAGE,
  REPORTS_INITIATIVES_PAGE,
  REPORTS_DAILY_INITIATIVES_PAGE,
  REPORTS_CAMPAIGNS_PAGE,
  REPORTS_DAILY_CAMPAIGNS_PAGE,
  REPORTS_ADGROUPS_PAGE,
  REPORTS_DAILY_ADGROUPS_PAGE,
  REPORTS_DATA_SOURCES_PAGE,
  REPORTS_LANDING_PAGES,
  REPORTS_CONTENT_ADS_PAGE,
];
