import {
  MANAGE_USERS,
  REPORTS_ACCOUNT_PAGE,
  REPORTS_INITIATIVES_PAGE,
  REPORTS_INTERNAL_ACCOUNT_PAGE,
  REPORTS_DATA_SOURCES_PAGE,
  REPORTS_CAMPAIGNS_PAGE,
  REPORTS_LANDING_PAGES,
  REPORTS_ADGROUPS_PAGE,
  REPORTS_CONTENT_ADS_PAGE,
  COS_DASHBOARD_PAGE,
  MAVEN_LEGACY_HOMEPAGE,
} from './routes';
import { ISidebarItem, ISidebarSection } from './types/Sidebar';

// ----------------------------------- REPORTS SIDEBAR SECTIONS AND ITEMS ----------------------------------- //
const ACCOUNT_PAGE: ISidebarItem = {
  title: 'accounts',
  pagePath: REPORTS_ACCOUNT_PAGE,
  icon: 'groups',
};
const INTERNAL_ACCOUNT_PAGE: ISidebarItem = {
  title: 'internal',
  pagePath: REPORTS_INTERNAL_ACCOUNT_PAGE,
  icon: 'group',
};
const INITIATIVES_PAGE: ISidebarItem = {
  title: 'initiatives',
  pagePath: REPORTS_INITIATIVES_PAGE,
  icon: 'campaign',
};
const CAMPAIGNS_PAGE: ISidebarItem = {
  title: 'campaigns',
  pagePath: REPORTS_CAMPAIGNS_PAGE,
  icon: 'surround_sound',
};
const ADGROUPS_PAGE: ISidebarItem = {
  title: 'adGroups',
  pagePath: REPORTS_ADGROUPS_PAGE,
  icon: 'group_work',
};
const DATA_SOURCES_PAGE: ISidebarItem = {
  title: 'dataSources',
  pagePath: REPORTS_DATA_SOURCES_PAGE,
  icon: 'storageoutlined',
};

const LANDING_PAGES_PAGE: ISidebarItem = {
  title: 'landingPages',
  pagePath: REPORTS_LANDING_PAGES,
  icon: 'weboutlined',
};

const CONTENT_ADS_PAGE: ISidebarItem = {
  title: 'contentAds',
  pagePath: REPORTS_CONTENT_ADS_PAGE,
  icon: 'campaignoutlined',
};

export const REPORTS_SECTION: ISidebarSection = {
  title: 'reports',
  childItems: [
    ACCOUNT_PAGE,
    INTERNAL_ACCOUNT_PAGE,
    DATA_SOURCES_PAGE,
    INITIATIVES_PAGE,
    LANDING_PAGES_PAGE,
    CAMPAIGNS_PAGE,
    ADGROUPS_PAGE,
    CONTENT_ADS_PAGE,
  ],
  icon: 'assessment',
  isBeta: true,
};

// ----------------------------------- MANAGE SIDEBAR SECTIONS AND ITEMS ----------------------------------- //
const MANAGE_USERS_PAGE: ISidebarItem = {
  title: 'users',
  pagePath: MANAGE_USERS,
  icon: 'people_outline',
};

export const MANAGE_SECTION: ISidebarSection = {
  title: 'manage',
  childItems: [MANAGE_USERS_PAGE],
  icon: 'device_hub',
  isBeta: true,
};

// ----------------------------------- MONITOR SIDEBAR SECTIONS AND ITEMS ----------------------------------- //

export const COS_DATA_PAGE: ISidebarItem = {
  title: 'cosData',
  pagePath: COS_DASHBOARD_PAGE,
  icon: 'shopping_cart_outlined',
};

export const MONITOR_SECTION: ISidebarSection = {
  title: 'monitor',
  childItems: [COS_DATA_PAGE],
  icon: 'monitor_heart_outlined',
};

// ----------------------------------- GENERAL SIDEBAR SECTIONS AND ITEMS ----------------------------------- //

export const BACK_TO_MAVEN_LEGACY: ISidebarItem = {
  title: 'backToMavenLegacy',
  pagePath: MAVEN_LEGACY_HOMEPAGE || '',
  icon: 'arrow_back',
};
