import { useTranslation } from 'react-i18next';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { FILTER_DROPDOWN_HEIGHT, FILTER_DROPDOWN_WIDTH } from 'constants/common';
import { IFilterMenu } from 'constants/types/Filter';
import { useContextValues } from 'context/UserContext';
import { get } from 'lodash';

const SelectRole = ({ filter, updateAction }: IFilterMenu) => {
  const { roles } = useContextValues();
  const role = get(filter, 'role', 0);
  const { t } = useTranslation();
  const translationPath = 'filters.reports';

  return (
    <>
      <Typography paddingBottom={3} variant="h6">
        {t(`${translationPath}.roles`)}
      </Typography>
      <FormControl fullWidth size="small">
        <InputLabel id="select-roles">{t(`${translationPath}.roles`)}</InputLabel>
        <Select
          labelId="select-roles"
          label={t(`${translationPath}.roles`)}
          defaultValue={role}
          MenuProps={{
            style: { maxHeight: FILTER_DROPDOWN_HEIGHT, width: FILTER_DROPDOWN_WIDTH },
          }}
          sx={{ width: FILTER_DROPDOWN_WIDTH }}
          onChange={(event: SelectChangeEvent<number>) => {
            const {
              target: { value },
            } = event;
            updateAction({ ...filter, role: value });
          }}
        >
          <MenuItem value="">
            <em>{t(`${translationPath}.selectRole`)}</em>
          </MenuItem>
          {roles.map(({ id, value, text }) => (
            <MenuItem
              key={id}
              value={value || ''}
              sx={{ whiteSpace: 'normal', wordBreak: 'break-all' }}
            >
              <Typography variant="inherit">{text}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default SelectRole;
