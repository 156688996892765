import { Box, Unstable_Grid2 as Grid, Toolbar } from '@mui/material';
import SidebarSection from '../SidebarSection';
import hasPermission from 'helpers/hasPermission';
import SidebarLink from '../SidebarLink';
import SidebarFooter from '../SidebarFooter';
import { APPBAR_HEIGHT } from 'constants/common';
import {
  BACK_TO_MAVEN_LEGACY,
  MANAGE_SECTION,
  MONITOR_SECTION,
  REPORTS_SECTION,
} from 'constants/sidebarSections';
import { Permission } from 'constants/types/Permission';
import { IUser } from 'constants/types/User';

type UserInfo = {
  user: IUser;
};

const SidebarContent = ({ user }: UserInfo) => (
  <Grid container direction="column" justifyContent="space-between" height="100%">
    <Box>
      <Toolbar sx={{ height: (theme) => theme.spacing(APPBAR_HEIGHT) }} />
      <SidebarSection {...REPORTS_SECTION} />
      <SidebarSection {...MANAGE_SECTION} />
      {hasPermission(Permission.MAVEN_MONITOR, user) && <SidebarSection {...MONITOR_SECTION} />}
      <SidebarLink {...BACK_TO_MAVEN_LEGACY} />
    </Box>

    <SidebarFooter />
  </Grid>
);

export default SidebarContent;
