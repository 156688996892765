import { Menu } from '@mui/icons-material';
import { AppBar, IconButton, Toolbar, useMediaQuery, useTheme, Box } from '@mui/material';
import ApprovalStatus from 'components/ApprovalStatus';
import { APPBAR_HEIGHT } from 'constants/common';
import { useContextValues } from 'context/UserContext';
import Breadcrumbs from 'components/Breadcrumbs';
import { REPORT_PAGES } from 'constants/routes';
import { useLocation } from 'react-router-dom';
import ViewAsFilter from 'components/FilterMenus/ViewAsFilter';
import logoTransparent from 'images/atn-logo-transparent.png';
const Header = () => {
  const { toggleSettings } = useContextValues();
  const theme = useTheme();
  const { pathname } = useLocation();
  const shouldSaveSettings = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <AppBar
      position="sticky"
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        display: 'flex',
        height: theme.spacing(APPBAR_HEIGHT),
      }}
      elevation={0}
      variant="outlined"
      color="inherit"
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => toggleSettings('expandedSidebar', shouldSaveSettings)}
          edge="start"
          sx={{
            marginRight: 5,
          }}
        >
          <Menu />
        </IconButton>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '115px',
          }}
        >
          <img src={logoTransparent} alt="Adtechnacity Logo" height="23" />
        </Box>
        <Box sx={{ flexGrow: 30 }}>
          <Breadcrumbs />
        </Box>

        {REPORT_PAGES.some((p) => pathname.includes(p)) && (
          <>
            <ApprovalStatus />
            <ViewAsFilter />
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
