import { AxiosRequestConfig } from 'axios';
import { IColumn, IRow, ISortingOptions } from './DataTable';
import { Filter, IReportsFilter } from './Filter';

export interface IReport {
  id: number;
  name: string;
  columns: IColumn[];
  sortBy: ISortingOptions;
}

export interface IReportData {
  page: number;
  pageSize: number;
  total: number;
  data: IReportRow[];
  summary: IReportRow;
}

export interface IInitiative {
  initiativeId: number;
  reports: IReport[];
}

export interface IUserReportColumns {
  name: string;
  reportName: string;
  hidden: boolean;
}

export interface IUserReportSettings {
  userReportColumns: IUserReportColumns[];
  reports: IReport[];
  initiatives: IInitiative[];
}

export interface IReportRow extends IRow {
  dcmClicks?: number | null;
  accountId: number;
  dataSourceId: number;
  accountName: string;
  mobileRevenue?: number | null;
  desktopRevenue?: number | null;
  superSectionVisits?: number | null;
  superSectionNoRevenuePageViews?: number | null;
  superSectionHomepagePageViews?: number | null;
  superSectionNcHomePageViews?: number | null;
  superSectionNcYmmPageViews?: number | null;
  superSectionEditorialPageViews?: number | null;
  costPerSuperSectionHomepagePageViews?: number | null;
  effectiveCostPerSuperSectionHomepagePageViews?: number | null;
  revenuePerSuperSectionVisits?: number | null;
  pacing?: number | null;
  budgetRemaining?: number | null;
  allowedRedirects?: number | null;
  blockedTraffic?: number | null;
  blockedTrafficRate?: number | null;
  whitelistedTraffic?: number | null;
  moatIvtRate?: number | null;
  remainingIvtRate?: number | null;
  blockedTrafficBreakdown?: string | null;
  whitelistedTrafficBreakdown?: string | null;
  moatIvtBreakdown?: number | null;
  remainingIvtBreakdown?: number | null;
  pageviewsAnalyzed?: number | null;
  clientIvt?: number | null;
  clientTrafficIvt?: number | null;
  videoStart?: number | null;
  videoQ1?: number | null;
  videoMid?: number | null;
  videoQ3?: number | null;
  videoComplete?: number | null;
  costPerView?: number | null;
  costPerCompletedView?: number | null;
  effectiveCostPerView?: number | null;
  effectiveCostPerCompletedView?: number | null;
  qualityScore?: number | null;
  scoreCoverage?: number | null;
  uninstalls?: number | null;
  uninstallRate?: number | null;
  date: Date | string;
  hour: number | null;
  datePart?: any;
  clicks: number | null;
  visits?: number | null;
  pageViews?: number | null;
  actions?: number | null;
  actions2?: number | null;
  actions3?: number | null;
  bouncedVisits?: number | null;
  entries?: number | null;
  impressions: number | null;
  discrepancy?: number | null;
  pageViewsPerVisit?: number | null;
  costPerClick?: number | null;
  costPerPageView?: number | null;
  costPerVisit?: number | null;
  costPerAction?: number | null;
  costPerAction2?: number | null;
  costPerAction3?: number | null;
  costPerThousandImpressions?: number | null;
  clicksThroughRatio?: number | null;
  bounceRate?: number | null;
  mediaCost: number | null;
  fees?: number | null;
  spend?: number | null;
  revenue?: number | null;
  profit?: number | null;
  returnOnAdSpend?: number | null;
  returnOnInvestment?: number | null;
  revenuePerClick?: number | null;
  effectiveRevenuePerClick?: number | null;
  effectiveClicks?: number | null;
  effectiveImpressions?: number | null;
  effectiveVisits?: number | null;
  effectivePageViews?: number | null;
  effectivePageViewsPerVisit?: number | null;
  effectiveDiscrepancy?: number | null;
  effectiveCostPerClick?: number | null;
  effectiveCostPerVisit?: number | null;
  effectiveCostPerPageView?: number | null;
  effectiveCostPerAction?: number | null;
  effectiveCostPerAction2?: number | null;
  effectiveCostPerAction3?: number | null;
  effectiveCostPerThousandImpressions?: number | null;
  effectiveClicksThroughRatio?: number | null;
  marginTotal?: number | null;
  margin?: number | null;
  approved: boolean;
  conversionRate?: number | null;
  conversionRate2?: number | null;
  conversionRate3?: number | null;
  conversionRate4?: number | null;
  conversionRate5?: number | null;
  conversionRate6?: number | null;
  effectiveConversionRate?: number | null;
  effectiveConversionRate2?: number | null;
  effectiveConversionRate3?: number | null;
  effectiveConversionRate4?: number | null;
  effectiveConversionRate5?: number | null;
  effectiveConversionRate6?: number | null;
  redirects?: number | null;
  revenuePerActions?: number | null;
  revenuePerActions2?: number | null;
  revenuePerActions3?: number | null;
  displayTimeunit: string | null;
}

export interface IInitiativeRow extends IReportRow {
  initiativeName?: string;
  initiativeId?: string | null;
  initiativeGroupId?: number | null;
  initiativeGroupName?: string | null;
}

export interface ICampaignRow extends IInitiativeRow {
  campaignExternalId?: number;
  campaignName?: string;
  externalCampaignId?: string;
}

export interface IAdGroupRow extends ICampaignRow {
  adGroupExternalId?: number;
  externalAdGroupId?: string;
  adGroupName?: string;
}

export interface IDataSourceReportRow extends IReportRow {
  dataSourceName?: string | null;
}

export interface ILandingPageReportRow extends IReportRow {
  landingPageId?: number;
  landingPageName?: string | null;
  dataSourceIds?: number[];
  campaignId?: number | null;
  initiativeId?: string | null;
  detail?: string | undefined;
  goalValue?: number | null;
  goalType?: number | null;
  effectiveGoalValue?: number | null;
  mediaSourceExternalId?: number;
  placementIds?: number | null;
}

export interface IContentAdReportRow extends IAdGroupRow {
  contentAdExternalId?: number;
  externalContentAdId?: string;
  contentAdName?: string;
  description?: string;
  imageUrl?: string;
  adGroupExternalId?: number;
  campaignExternalId?: number;
  mediaSourceExternalId?: number;
  mediaSourceName?: string;
  revenuePerThousandImpressions?: number;
  effectiveRevenuePerThousandImpressions?: number;
}

export interface IReportQueryOptions {
  startDate?: string;
  endDate?: string;
  orderBy?: string;
  asc?: boolean;
  page?: number;
  pageSize?: number;
  internalAccount?: boolean;
  actual?: boolean;
  initiativeGroupId?: number;
  initiativeId?: string;
  campaignId?: number;
  landingPageId?: number;
  adGroupId?: number;
  contentAdId?: number;
}

export interface IReportsPage {
  initialReportsData: IReportRow[];
  initialReportsSummary: IReportRow;
  initialQueryOptions: IReportQueryOptions;
  initialFilter: IReportsFilter;
}

interface IReportGrouping {
  reportId: number;
  dataEndpoint: (query: IReportQueryOptions, filter: Filter | null) => AxiosRequestConfig;
}

export interface IReportGroupingConfig {
  All: IReportGrouping;
  Daily?: IReportGrouping;
  Hourly?: IReportGrouping;
}

export enum GroupBy {
  All = 'All',
  Daily = 'Daily',
  Hourly = 'Hourly',
}

export type ReportRow =
  | ICampaignRow
  | IInitiativeRow
  | IDataSourceReportRow
  | IReportRow
  | ILandingPageReportRow
  | IAdGroupRow
  | IContentAdReportRow;

export enum ReportType {
  ACCOUNT_REPORT = 'accounts',
  INITIATIVE_REPORT = 'initiatives',
  CAMPAIGN_REPORT = 'campaigns',
  LANDING_PAGE_REPORT = 'landing-pages',
  AD_GROUPS_REPORT = 'ad-groups',
  CONTENT_ADS_REPORT = 'content-ads',
  DATA_SOURCE_REPORT = 'traffic-sources',
}
