import { useTranslation } from 'react-i18next';
import { Avatar, Button, IconButton, Paper, Unstable_Grid2 as Grid } from '@mui/material';
import { IUser, IUserSettings } from 'constants/types/User';
import { useContextValues } from 'context/UserContext';
import { useNavigate } from 'react-router-dom';
import { PROFILE_PAGE } from 'constants/routes';

const SidebarFooter = () => {
  const navigate = useNavigate();
  const { user, logout } = useContextValues();
  const { firstName, lastName, userSettings } = user as IUser;
  const { expandedSidebar } = userSettings as IUserSettings;
  const { t } = useTranslation();
  const translationPath = 'sidebar.footer';

  const goToProfile = () => {
    navigate(PROFILE_PAGE);
  };
  const signOut = async () => {
    await logout();
  };

  return (
    <>
      <Paper elevation={0} sx={{ marginBottom: 10 }}>
        <Grid container>
          <Grid>
            <IconButton onClick={goToProfile}>
              <Avatar sx={{ bgcolor: '#FF8000' }}>{`${firstName?.charAt(0)}${lastName?.charAt(
                0,
              )}`}</Avatar>
            </IconButton>
          </Grid>
          {expandedSidebar ? (
            <Grid container direction="column">
              <Grid>
                <Button
                  variant="text"
                  color="secondary"
                  size="small"
                  sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
                  onClick={goToProfile}
                >
                  {t(`${translationPath}.profile`)}
                </Button>
              </Grid>
              <Grid>
                <Button
                  variant="text"
                  size="small"
                  sx={{ color: 'grey.500', textTransform: 'none', justifyContent: 'flex-start' }}
                  onClick={signOut}
                >
                  {t(`${translationPath}.signOut`)}
                </Button>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Paper>
    </>
  );
};

export default SidebarFooter;
