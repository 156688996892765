import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import {
  PROFILE_PAGE,
  REPORTS_ACCOUNT_PAGE,
  MANAGE_USERS,
  REPORTS_DATA_SOURCES_PAGE,
  COS_DASHBOARD_PAGE,
} from 'constants/routes';
import { NAME_OF_CURRENT_ENTITY_KEY } from 'constants/common';
import Crumb from './Crumb';

const Breadcrumbs = () => {
  const { pathname } = useLocation();

  const breadcrumbs = useMemo(() => {
    const nestedRoutes = pathname.split('/').filter((r) => r.length > 0);

    const crumbList = nestedRoutes.map((subRoute, index) => {
      const href = `/${nestedRoutes.slice(0, index + 1).join('/')}`;
      const text = subRoute;

      return { href, text };
    });

    return crumbList;
  }, [pathname]);

  return (
    <>
      <MuiBreadcrumbs sx={{ ml: 4 }}>
        {breadcrumbs.map((crumb) => {
          if (crumb.href === COS_DASHBOARD_PAGE)
            return <Crumb href={crumb.href} text="COS" key={crumb.text} />;
          if (crumb.href === PROFILE_PAGE)
            return <Crumb href={crumb.href} text="Profile" key={crumb.text} />;
          if (crumb.href === REPORTS_DATA_SOURCES_PAGE)
            return <Crumb href={crumb.href} text="Data Sources" key={crumb.text} />;
          if (crumb.text === 'reports')
            return <Crumb href={REPORTS_ACCOUNT_PAGE} text={crumb.text} key={crumb.text} />;
          if (crumb.text === 'manage')
            return <Crumb href={MANAGE_USERS} text={crumb.text} key={crumb.text} />;
          if (crumb.text === 'monitor')
            return <Crumb href={COS_DASHBOARD_PAGE} text="Monitor" key={crumb.text} />;
          if (crumb.text === '[id]')
            return (
              <Crumb
                href={pathname}
                text={localStorage.getItem(NAME_OF_CURRENT_ENTITY_KEY) as string}
                key={crumb.text}
              />
            );
          return <Crumb {...crumb} key={crumb.text} />;
        })}
      </MuiBreadcrumbs>
    </>
  );
};

export default Breadcrumbs;
