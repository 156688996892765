import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { EDIT_USER, MANAGE_PAGES, USER_DETAILS } from 'constants/routes';
import Loader from 'components/common/Loader';

const UserDetails = lazy(() => import('pages/manage/users/detail'));
const EditUser = lazy(() => import('pages/manage/users/edit'));

const ManageRoutes = () => (
  <Suspense fallback={<Loader />}>
    <Routes>
      {MANAGE_PAGES.map((path: string) => {
        const LazyComponent = lazy(() => import(`pages/${path.substring(1)}`));
        return <Route key={path} path={path.substring(8)} element={<LazyComponent />} />;
      })}
      <Route path={USER_DETAILS} element={<UserDetails />} />;
      <Route path={EDIT_USER} element={<EditUser />} />;
    </Routes>
  </Suspense>
);

export default ManageRoutes;
