import { useTranslation } from 'react-i18next';
import { Button, Menu, Paper, Stack, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { useState, useEffect } from 'react';
import { IApprovedReportData } from 'constants/types/ApprovedData';
import { useContextValues } from 'context/UserContext';
import fetcher from 'helpers/fetcher';
import { GET_REALTIME_APPROVAL_STATUS, GET_RANGE_APPROVAL_STATUS } from 'constants/endpoints';
import ApprovedDataListItem from 'components/ApprovalStatus/ApprovedDataListItem';
import { difference } from 'lodash';
import moment from 'moment';

type ApprovalButtonColor = 'success' | 'error' | 'primary';

const ApprovalStatus = () => {
  const { reportQueryOptions } = useContextValues();
  const [approvedData, setApprovedData] = useState<IApprovedReportData[]>([]);
  const [buttonColor, setButtonColor] = useState<ApprovalButtonColor>();
  const { startDate, endDate } = reportQueryOptions;
  const isRealtimeData = startDate === endDate && startDate === moment().format('YYYY-MM-DD');
  const { t } = useTranslation();
  const translationPath = 'approvalStatus';

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const getApprovedData = async () => {
      if (isRealtimeData) {
        const { data } = await fetcher(GET_REALTIME_APPROVAL_STATUS);
        if (data) {
          setApprovedData(data);
        }
      } else {
        const requestConfig = GET_RANGE_APPROVAL_STATUS({ startDate, endDate });
        const { data } = await fetcher(requestConfig);
        if (data) {
          setApprovedData(data);
        }
      }
    };

    getApprovedData();
  }, [startDate, endDate, isRealtimeData]);

  const totalReportCount = approvedData.reduce((a, b) => a + b.totalCount, 0);

  const totalSuccessfulImports = approvedData.reduce((a, b) => a + b.successCount, 0);

  const completeSuccess = totalSuccessfulImports === totalReportCount;
  const unSuccessfulImport = approvedData.find((data) => data.status === 3);

  useEffect(() => {
    if (completeSuccess) setButtonColor('success');
    else if (unSuccessfulImport) setButtonColor('error');
    else setButtonColor('primary');
  }, [completeSuccess, unSuccessfulImport]);

  const platforms = approvedData.filter((a) => a.isDataSource);
  const performances = difference(approvedData, platforms);
  const buttonLabel = t(`${translationPath}.reports`);

  return (
    <>
      <Button
        startIcon={completeSuccess ? <CircleIcon /> : <CircleOutlinedIcon />}
        size="small"
        color={buttonColor}
        sx={{
          '&:hover': { backgroundColor: 'transparent' },
          textTransform: 'none',
        }}
        onClick={handleClick}
      >
        {`${totalSuccessfulImports}/${totalReportCount} ${buttonLabel}`}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Paper elevation={0} sx={{ backgroundColor: 'transparent', width: 260, p: 4 }}>
          <Stack direction="column" spacing={2}>
            <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>
              {t(`${translationPath}.platform`)}
            </Typography>
            {platforms.map((platform) => {
              return (
                <ApprovedDataListItem
                  data={platform}
                  isRealtime={isRealtimeData}
                  key={platform.id}
                />
              );
            })}
            <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>
              {t(`${translationPath}.performance`)}
            </Typography>
            {performances.map((performance) => {
              return (
                <ApprovedDataListItem
                  data={performance}
                  isRealtime={isRealtimeData}
                  key={performance.id}
                />
              );
            })}
          </Stack>
        </Paper>
      </Menu>
    </>
  );
};

export default ApprovalStatus;
