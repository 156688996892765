import { useTranslation } from 'react-i18next';
import { Button, Menu, Paper, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { useState } from 'react';
import { useContextValues } from 'context/UserContext';
import { IUser } from 'constants/types/User';
import SelectAccount from 'components/Inputs/FilterInputs/SelectInputs/SelectAccount';
import { IImpersonateFilter } from 'constants/types/Filter';
import { VisibilityOutlined } from '@mui/icons-material';
import SelectRole from 'components/Inputs/FilterInputs/SelectInputs/SelectRole';
import { FilterProvider } from 'context/FilterContext';

const ViewAsFilter = () => {
  const { user, impersonateUser, accounts } = useContextValues();
  const { role: userRole } = user as IUser;
  const [impersonateFilter, setImpersonateFilter] = useState<IImpersonateFilter>({});
  const [isImpersonating, setIsImpersonating] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const translationPath = 'viewAs';

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleImpersonateChange = (filter: IImpersonateFilter) => {
    setImpersonateFilter(filter);
  };

  const apply = async () => {
    const { accountId, role } = impersonateFilter;
    const accountName = accounts?.find((a) => a.id === accountId)?.name;

    if (accountName && role) {
      const success = await impersonateUser({
        account: accountName,
        impersonate: 'impersonate',
        role,
      });
      if (success) setIsImpersonating(true);
    }
  };

  const reset = async () => {
    if (isImpersonating) {
      const success = await impersonateUser({ account: '', impersonate: 'reset', role: userRole });
      if (success) {
        setIsImpersonating(false);
        setImpersonateFilter({ accountId: 0, role: userRole });
      }
    }
  };

  return (
    <FilterProvider>
      <Button
        startIcon={<VisibilityOutlined />}
        color={isImpersonating ? 'primary' : 'secondary'}
        size="small"
        sx={{
          '&:hover': { backgroundColor: 'transparent' },
          textTransform: 'none',
        }}
        onClick={handleClick}
      >
        <Typography variant="button" color="secondary">
          {t(`${translationPath}.label`)}
        </Typography>
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Paper
          elevation={0}
          sx={{
            width: 300,
            maxWidth: '100%',
            height: '100%',
            paddingX: 6,
            paddingY: 3,
          }}
        >
          <Grid container spacing={4}>
            <Grid xs={12}>
              <SelectAccount filter={impersonateFilter} updateAction={handleImpersonateChange} />
            </Grid>

            <Grid xs={12}>
              <SelectRole filter={impersonateFilter} updateAction={handleImpersonateChange} />
            </Grid>

            <Grid container justifyContent="flex-end" direction="row" spacing={2}>
              <Grid>
                <Button onClick={reset}>{t(`${translationPath}.reset`)}</Button>
              </Grid>
              <Grid>
                <Button onClick={apply}>{t(`${translationPath}.apply`)}</Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Menu>
    </FilterProvider>
  );
};

export default ViewAsFilter;
