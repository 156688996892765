const breakpoints = {
  values: {
    xs: 480,
    sm: 600,
    md: 768,
    lg: 1280,
    xl: 1440,
  },
};

export default breakpoints;
