import { Icon, Link, Tooltip, Typography } from '@mui/material';
import { ISidebarItem } from 'constants/types/Sidebar';
import { useTranslation } from 'react-i18next';

const SidebarLink = ({ icon, title, pagePath }: ISidebarItem) => {
  const { t } = useTranslation();
  const translationPath = 'sidebar.items';
  return (
    <Tooltip title={t(`${translationPath}.${title}`)} placement="bottom-start">
      <Link href={pagePath} underline="hover" color={(theme) => theme.palette.text.primary}>
        <Typography display="flex" variant="caption" fontWeight="bold" columnGap={6} margin={4}>
          <Icon fontSize="small">{icon}</Icon>
          {t(`${translationPath}.${title}`)}
        </Typography>
      </Link>
    </Tooltip>
  );
};

export default SidebarLink;
