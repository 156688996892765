import { Link } from 'react-router-dom';
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter';

interface ICrumbProps {
  href: string;
  text: string;
}

const Crumb = ({ href, text }: ICrumbProps) => {
  return (
    <Link to={href} style={{ color: '#00000099', textDecoration: 'none' }}>
      {capitalizeFirstLetter(text)}
    </Link>
  );
};

export default Crumb;
