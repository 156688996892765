import React, { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { REPORTS_ACCOUNT_PAGE, REPORT_PAGES } from 'constants/routes';
import Loader from 'components/common/Loader';

const ReportRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {REPORT_PAGES.map((path: string) => {
          const LazyComponent = lazy(() => import(`pages/${path.substring(1)}`));
          return <Route key={path} path={path.substring(9)} element={<LazyComponent />} />;
        })}
        <Route path="*" element={<Navigate replace to={REPORTS_ACCOUNT_PAGE} />} />
      </Routes>
    </Suspense>
  );
};

export default ReportRoutes;
