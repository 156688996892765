import moment from 'moment';
import { SORT_BY_REDIRECTS } from './common';
import { IReportQueryOptions } from './types/Report';

export const ACCOUNT_REPORTS_ID = 84;
export const DAILY_REPORTS_ID = 40;
export const INITIATIVE_REPORTS_ID = 63;
export const DATA_SOURCES_REPORTS_ID = 69;
export const LANDING_PAGES_REPORTS_ID = 64;
export const CAMPAIGNS_REPORTS_ID = 66;
export const ADGROUP_REPORTS_ID = 67;
export const CONTENT_ADS_REPORTS_ID = 68;

export const LP_NAME_COLUMN_ID = 137;

export const INITIAL_QUERY_OPTIONS: IReportQueryOptions = {
  startDate: moment().format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  orderBy: SORT_BY_REDIRECTS,
  asc: false,
  page: 0,
  pageSize: 0,
};

export const INITIATIVE_ID = 'initiativeId';
export const INITIATIVE_GROUP_ID = 'initiativeGroupId';
export const CAMPAIGN_ID = 'campaignExternalId';
export const LANDING_PAGE_ID = 'landingPageId';
export const CONTENT_AD_ID = 'contentAdExternalId';
export const ADGROUP_ID = 'adGroupExternalId';

export const GROUPBY_REPORT_TIMEKEYS = {
  All: 'date',
  Daily: 'date',
  Hourly: 'hour',
};
