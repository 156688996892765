import { createTheme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';
import typography from './typography';
import breakpoints from './breakpoints';
import getThemePalette from './palette';

const getTheme = (mode: PaletteMode) => {
  return createTheme({
    spacing: 4,
    breakpoints,
    typography,
    palette: getThemePalette(mode),
  });
};

export default getTheme;
