import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { useContextValues } from 'context/UserContext';
import { get } from 'lodash';
import { Box, Drawer, useMediaQuery, useTheme } from '@mui/material';
import SidebarContent from './SidebarContent';

const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(15)})`,
  [theme.breakpoints.down('md')]: {
    width: `calc(${theme.spacing(16)})`,
  },
});

const BigScreenDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ open, theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const Sidebar = ({ children }: any) => {
  const { user } = useContextValues();
  const theme = useTheme();
  const expandedSidebar = get(user, 'userSettings.expandedSidebar', false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ display: 'flex' }}>
      {isSmallScreen ? (
        <Drawer
          variant="temporary"
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: 'flex',
          }}
          open={expandedSidebar}
        >
          <SidebarContent user={user} />
        </Drawer>
      ) : (
        <BigScreenDrawer
          variant="permanent"
          open={expandedSidebar}
          color="default"
          sx={{
            display: 'flex',
          }}
        >
          <SidebarContent user={user} />
        </BigScreenDrawer>
      )}
      <Box
        sx={{
          flexGrow: 1,
          width: expandedSidebar
            ? `calc(100% - ${theme.spacing(drawerWidth)})`
            : `calc(100% - ${theme.spacing(16)})`,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Sidebar;
