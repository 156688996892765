import { Filter } from 'constants/types/Filter';
import { IReportQueryOptions } from 'constants/types/Report';
import { isUndefined, omitBy, merge } from 'lodash';

const buildQueryParams = function buildQueryParamsHelper(
  queryAsObject: IReportQueryOptions,
  filter: Filter | null = null,
) {
  const encodedQuery = new URLSearchParams(
    omitBy(merge({}, queryAsObject, filter), isUndefined),
  ).toString();
  return decodeURIComponent(encodedQuery);
};

export default buildQueryParams;
