import { Roles } from './Role';

export interface IPermission {
  id: number;
  role?: Roles;
  permissionId?: number;
  component?: string;
  access?: number;
  description?: string;
}

export enum Permission {
  FRECUENCY_CAP = 1,
  MAVEN_MONITOR = 2,
}
