import { Box } from '@mui/material';
import Header from 'components/Header/Header';
import Sidebar from 'components/Sidebar';

const MavenLayout = ({ children }: any) => {
  return (
    <Box>
      <Header />
      <Sidebar>{children}</Sidebar>
    </Box>
  );
};

export default MavenLayout;
