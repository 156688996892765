import React from 'react';

import { UserProvider } from 'context/UserContext';

import Container from 'components/Container';

import 'i18n';

function App() {
  return (
    <UserProvider>
      <Container />
    </UserProvider>
  );
}

export default App;
