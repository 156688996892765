import { useTranslation } from 'react-i18next';
import { Autocomplete, TextField, Typography } from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import { IFilterData, IFilterMenu } from 'constants/types/Filter';
import { useFilterValues } from 'context/FilterContext';
import { find, get } from 'lodash';
import { REPORTS_ACCOUNT_PAGE } from 'constants/pageRoutes';
import { useSearchParams, useLocation } from 'react-router-dom';

const DEFAULT_VALUE = { id: 0, name: '' };

const SelectAccount = ({ filter, updateAction }: IFilterMenu) => {
  const [searchParams] = useSearchParams();
  const [fetched, setFetched] = useState(false);
  const { accounts, fetchAccounts } = useFilterValues();
  const { t } = useTranslation();
  const translationPath = 'filters.reports';
  const location = useLocation();

  const initialAccountId = get(searchParams, 'accountIds');

  const initialAccount = useMemo(
    () =>
      find(accounts, {
        id: get(filter, 'accountIds', initialAccountId),
      }) || DEFAULT_VALUE,
    [accounts, filter, initialAccountId],
  );

  const isInternal = useMemo(() => {
    const isInternal = String(!!searchParams.get('internalAccount'));
    const isAccountReportPage = location.pathname.includes(REPORTS_ACCOUNT_PAGE);
    return isAccountReportPage ? isInternal : null;
  }, [location.pathname, searchParams]);

  useEffect(() => {
    if (!fetched) {
      fetchAccounts({ internal: isInternal });
      setFetched(true);
    }
  }, [accounts, fetchAccounts, fetched, isInternal]);

  return (
    <>
      <Typography paddingBottom={3} variant="h6">
        {t(`${translationPath}.account`)}
      </Typography>
      <Autocomplete
        options={accounts}
        getOptionLabel={(option: IFilterData) => option.name}
        autoComplete
        value={initialAccount}
        onChange={(event: any, selected: IFilterData | null) => {
          updateAction({ ...filter, accountIds: selected?.id });
        }}
        includeInputInList
        renderInput={(params) => (
          <TextField {...params} label={t(`${translationPath}.account`)} size="small" />
        )}
      />
    </>
  );
};

export default SelectAccount;
