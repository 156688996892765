import { useTranslation } from 'react-i18next';
import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Icon,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { ISidebarSection } from 'constants/types/Sidebar';
import { useContextValues } from 'context/UserContext';
import { get } from 'lodash';
import SidebarItem from '../SidebarItem';
import { useLocation } from 'react-router-dom';

const SidebarSection = ({ title, icon, childItems, isBeta = false }: ISidebarSection) => {
  const { t } = useTranslation();
  const translationPath = 'sidebar.sections';
  const { user } = useContextValues();
  const expandedSidebar = get(user, 'userSettings.expandedSidebar', false);
  const { pathname } = useLocation();

  return (
    <Accordion disableGutters={true} square={true} elevation={0}>
      <AccordionSummary
        expandIcon={<KeyboardArrowDown />}
        sx={{
          '& .MuiAccordionSummary-expandIconWrapper': {
            display: 'block',
          },
        }}
      >
        <ListItem disablePadding={true}>
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: expandedSidebar ? 3 : 'auto',
              justifyContent: 'center',
              color: (theme) => (pathname.includes(title) ? theme.palette.primary.main : 'default'),
            }}
          >
            <Icon>{icon}</Icon>
          </ListItemIcon>
          <ListItemText
            primary={t(`${translationPath}.${title}`)}
            primaryTypographyProps={{ sx: { fontWeight: 'bold' } }}
            sx={{
              display: expandedSidebar ? 'flex' : 'none',
              color: (theme) => (pathname.includes(title) ? theme.palette.primary.main : 'default'),
            }}
          />
          {isBeta ? (
            <Chip
              label="beta"
              size="small"
              color="primary"
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'bold',
                fontSize: '.5rem',
              }}
            />
          ) : null}
        </ListItem>
      </AccordionSummary>

      <AccordionDetails>
        {childItems.map((sidebarItem) => (
          <SidebarItem key={sidebarItem.pagePath} {...sidebarItem} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default SidebarSection;
