import axios, { AxiosRequestConfig } from 'axios';

const fetcher = async function dataFetchHelper(
  requestConfig: AxiosRequestConfig,
  withCredentials: boolean = true,
): Promise<{ data?: any; error?: string }> {
  const config = {
    ...requestConfig,
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials,
  };

  try {
    const { data } = await axios(config);
    return { data };
  } catch (error: any) {
    return { error: error.message };
  }
};

export default fetcher;
