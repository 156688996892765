import { TypographyOptions } from '@mui/material/styles/createTypography';

const typography: TypographyOptions = {
  fontFamily: ['Roboto', 'Arial', 'sans-serif'].join(','),
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  h1: {
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: 1.4,
  },
  h2: {
    fontSize: '1.75rem',
    fontWeight: 700,
    lineHeight: 1.4,
  },
  h3: {
    fontSize: '1.5rem',
    fontWeight: 700,
    lineHeight: 1.4,
  },
  h4: {
    fontSize: '1.35rem',
    fontWeight: 700,
    lineHeight: 1.4,
  },
  h5: {
    fontSize: '1.125rem',
    fontWeight: 700,
    lineHeight: 1.2,
  },
  h6: {
    fontSize: '1.0625rem',
    fontWeight: 700,
    lineHeight: 1.2,
  },
  body1: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.2,
  },
};

export default typography;
