import Loader from 'components/common/Loader';
import {
  COS_DASHBOARD_PAGE,
  HOME_PAGE,
  MONITOR_REDIRECT_URL,
  PROFILE_PAGE,
} from 'constants/routes';
import { Permission } from 'constants/types/Permission';
import { IUser } from 'constants/types/User';
import hasPermission from 'helpers/hasPermission';
import React, { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const MyProfile = lazy(() => import('pages/MyProfile'));
const CosData = lazy(() => import('pages/monitor/cos-data'));

type UserInfo = {
  user: IUser;
};

const GeneralRoutes = ({ user }: UserInfo) => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path={PROFILE_PAGE} element={<MyProfile />} />
        {hasPermission(Permission.MAVEN_MONITOR, user) && (
          <>
            <Route path={COS_DASHBOARD_PAGE} element={<CosData />} />
            <Route
              path={MONITOR_REDIRECT_URL}
              element={<Navigate replace to={COS_DASHBOARD_PAGE} />}
            />
          </>
        )}
        <Route path="*" element={<Navigate replace to={HOME_PAGE} />} />
      </Routes>
    </Suspense>
  );
};

export default GeneralRoutes;
